import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import Publication from 'Components/publications/Publication';
import pubIcon from 'Assets/icons/pub_icon.svg';

const RecentPublications = () => {
  const { allMarkdownRemark: { edges: publications } } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          frontmatter: {templateKey: {eq: "publication"}}
        },
        sort: {
          order: DESC,
          fields: frontmatter___date
        },
        limit: 6
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              authors
              journal
              publicationYear
              date
              link
              pmid
            }
          }
        }
      }
    }
  `);

  return (
    <div className="publications__wrapper publications__wrapper--home">
      <h2 className="page__title">Newest publications</h2>
      <ul className="publications__list">
        { publications.map(({ node: publication }) => (
          <li className="publication__row " key={ publication.id }>
            <img className="publication__row__icon" src={ pubIcon } alt="publication" />
            <Publication publication={ publication.frontmatter } />
          </li>
        )) }
      </ul>
    </div>
  );
};

export default RecentPublications;
