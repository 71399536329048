import React, { useEffect, useRef } from 'react';

import Img from 'gatsby-image';
import { Link } from 'gatsby';

import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';

const FeaturedItem = ({ item }) => (
  item.linkType === 'internal'
    ? (
      <div className="homepage-featured_slider-item" key={ item.title }>
        <div className="homepage-featured">
          { !!item?.image?.childImageSharp && (
            <Link to={ item.linkURL } className="homepage-featured__img__link">
              <Img className="homepage-featured__img" fluid={ item.image.childImageSharp.fluid } />
            </Link>
          ) }
          <div className="homepage-featured__copy">
            <h2 className="homepage-featured__title"><Link to={ item.linkURL }>{ item.title }</Link></h2>
            { item.showReadMore
            && <Link to={ item.linkURL } className="homepage-featured__cta">Read More</Link> }
          </div>
        </div>
      </div>
    )
    : (
      <div className="homepage-featured_slider-item" key={ item.title }>
        <div className="homepage-featured">
          { !!item?.image?.childImageSharp && (
            <a href={ item.linkURL } className="homepage-featured__img__link" target="_blank" rel="noopener noreferrer">
              <Img className="homepage-featured__img" fluid={ item.image.childImageSharp.fluid } />
            </a>
          ) }
          <div className="homepage-featured__copy">
            <h2 className="homepage-featured__title"><a href={ item.linkURL } target="_blank" rel="noopener noreferrer">{ item.title }</a></h2>
            { item.showReadMore
           && <a className="homepage-featured__cta" href={ item.linkURL } target="_blank" rel="noopener noreferrer">Read More</a> }
          </div>
        </div>
      </div>
    )
);

const FeaturedList = ({ items }) => {
  const container = useRef(null);

  useEffect(() => {
    const containerCur = document.querySelector('.homepage-featured__slider');

    if (!container.current) {
      return () => {};
    }

    const slider = tns({
      container: containerCur,
      gutter: 10,
      controlsPosition: 'top',
      controlsText: [ '', '' ],
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayButtonOutput: false,
      navPosition: 'bottom',
      arrowKeys: true,
      loop: true,
      mouseDrag: true,
    });

    return () => {
      slider.destroy();
    };
  }, [ container ]);

  return (
    <div className="homepage-featured__slider" ref={ container }>
      { items.map((item, key) => (
        <FeaturedItem
          item={ item }
          key={ `item-${key * key}` }
        />
      )) }
    </div>
  );
};

export default FeaturedList;
