/* eslint-disable react/no-danger */
import React from 'react';

import { graphql } from 'gatsby';

import SEO from 'Components/SEO';
import RecentPublications from 'Components/publications/RecentPublications';
import FeaturedList from 'Components/featured/List';
import LinkTiles from 'Components/LinkTiles';

import PageWrapper from 'Layouts/PageWrapper';

import pubIcon from 'Assets/icons/homepage_software.svg';

const IndexPage = ({ data }) => {
  const { indexData: { edges: [ { node: indexPage } ] } } = data;

  return (
    <PageWrapper>
      <SEO title="Switch Lab" />
      <div className="homepage-hero__wrapper">
        <div className="homepage-hero__main-title__wrapper">
          <h2 className="homepage-hero__main-pretitle">{ indexPage.frontmatter.pre_intro }</h2>
          <h1 className="homepage-hero__main-title">{ indexPage.frontmatter.intro }</h1>

          <div className="homepage-hero__about" dangerouslySetInnerHTML={ { __html: indexPage.html } } />
          <div className="homepage-hero__about__link__wrapper">
            <a className="homepage-hero__about__link" href={ indexPage.frontmatter.call2action.linkURL }>{ indexPage.frontmatter.call2action.title }</a>
          </div>
        </div>
      </div>
      <main className="page__content homepage-content">
        <div className="homepage-featured__wrapper">
          <FeaturedList items={ indexPage.frontmatter.featured } />
        </div>

        <div className="homepage-software__wrapper">
          <img className="homepage-software__icon" src={ pubIcon } alt="publication" />
          <h2 className="homepage-software__title">Learn More about our Software</h2>
          <a className="homepage-software__link" href="/software">Discover more</a>
        </div>

        <div className="homepage-publications">
          <RecentPublications />
        </div>
        <div className="homepage-link-tiles__wrapper">
          <h2>{ indexPage.frontmatter.linkTiles_intro }</h2>
          <LinkTiles items={ indexPage.frontmatter.linkTiles } />
        </div>
      </main>

    </PageWrapper>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    indexData: allMarkdownRemark(filter: {
      frontmatter: {
        templateKey: {eq: "index"},
      },
    }) {
      totalCount
      edges {
        node {
          html
          frontmatter {
            pre_intro
            intro
            call2action {
              title
              linkType
              linkURL
            }
            featured {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              linkType
              linkURL
              showReadMore
            }
            linkTiles_intro
            linkTiles {
              title
              linkType
              linkURL
              showReadMore
            }
          }
        }
      }
    }
  }
`;
