import React from 'react';

const LinkTiles = ({ items }) => (
  <div className="homepage-link-tiles__content">
    { items.map(item => (
      <div className="homepage-link-tile" key={ item.title }>
        <h3 className="homepage-link-tile__title">{ item.title }</h3>
        { item.showReadMore
        && (
          <a className="homepage-link-tile__cta" href={ item.linkURL } rel="noopener noreferrer">
            Read More
          </a>
        ) }
      </div>
    )) }
  </div>
);

export default LinkTiles;
